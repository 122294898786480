import Layout from '@/views/layout/Layout'
import { RouterView } from 'vue-router'

export default {
  path: '/journals',
  name: 'journals',
  redirect: '/journals/knm',
  component: Layout,
  meta: {
    title: 'Журналы',
    permissions: []
  },
  children: [
    {
      path: 'iogv',
      component: RouterView,
      name: 'iogvWrap',
      meta: { title: 'Реестр ИОГВ' },
      children: [
        {
          path: '',
          component: () => import('@/views/journals/iogv/IogvList'),
          name: 'iogvList',
          meta: {
            title: 'Реестр ИОГВ',

            permissions: []
          }
        },
        {
          path: 'create',
          component: () => import('@/views/journals/iogv/iogvEdit'),
          name: 'iogvCreate',
          hidden: true,
          meta: {
            title: 'Создание ИОГВ',
            permissions: []
          }
        },
        {
          path: 'edit/:id(\\d+)',
          component: () => import('@/views/journals/iogv/iogvEdit'),
          name: 'iogvEdit',
          hidden: true,
          meta: {
            title: 'Редактирование ИОГВ',
            permissions: []
          }
        }
      ]
    },

    {
      path: '/registry_sub_org/list',
      component: () => import('@/views/journals/subOrg/subOrgList'),
      name: 'registrySubOrg',
      meta: {
        title: 'Реестр подведомственных организаций',

        permissions: []
      }
    },
    {
      path: 'breadcrumbHelper',
      name: 'breadcrumbHelperSubOrg',
      hidden: true,
      component: () => import('@/views/Home'),
      redirect: '/registry_sub_org/list',
      meta: {
        title: 'Реестр подведомственных организаций'
      },
      children: [
        {
          path: '/registry_sub_org/create',
          component: () => import('@/views/journals/subOrg/subOrgEdit'),
          name: '/registry_sub_org/create',
          hidden: true,
          meta: {
            title: 'Создание подведомственной организации',
            permissions: []
          }
        },
        {
          path: '/registry_sub_org/edit/:id(\\d+)',
          component: () => import('@/views/journals/subOrg/subOrgEdit'),
          name: '/registry_sub_org/edit',
          hidden: true,
          meta: {
            title: 'Редактирование подведомственной организации',
            permissions: []
          }
        }
      ]
    },
    {
      path: 'knm',
      component: RouterView,
      name: 'knmWrap',
      meta: {
        title: 'Журнал КНМ'
      },
      children: [
        {
          path: '',
          component: () => import('@/views/journals/measureKnm/measureList'),
          name: 'logKnm',
          meta: {
            title: 'Журнал КНМ',
            permissions: []
          }
        },
        {
          path: 'create',
          component: () => import('@/views/journals/measureKnm/measureEdit'),
          name: 'knmCreate',
          hidden: true,
          meta: {
            title: 'Создание КНМ',
            permissions: []
          }
        },
        {
          path: 'edit/:id(\\d+)',
          component: RouterView,
          name: 'knmEditWrap',
          hidden: true,
          meta: { title: 'Редактирование КНМ' },
          children: [
            {
              path: '',
              component: () => import('@/views/journals/measureKnm/measureEdit'),
              name: 'knmEdit',
              hidden: true,
              meta: {
                title: 'Редактирование КНМ',
                permissions: []
              },
              children: [

              ]
            },
            {
              path: 'mdr/edit/:mdrID(\\d+)',
              component: () => import('@/views/journals/measureKnm/measureDocs/mdrEdit'),
              name: 'mdrEdit',
              hidden: true,
              meta: {
                title: 'Редактирование запроса',
                permissions: []
              }
            },
            {
              path: 'mdr/create',
              component: () => import('@/views/journals/measureKnm/measureDocs/mdrEdit'),
              name: 'mdrCreate',
              hidden: true,
              meta: {
                title: 'Создание запроса',
                permissions: []
              }
            }
          ]
        }
      ]
    },

    {
      path: 'breadcrumbHelper',
      name: 'breadcrumbHelperKnmLegalAct',
      hidden: true,
      component: () => import('@/views/Home'),
      redirect: '/legal_acts/list',
      meta: {
        title: 'Правовой акт'
      },
      children: [
        {
          path: '/knm_legal_act/create/:knm_id(\\d+)',
          component: () => import('@/views/journals/measureKnm/knmLegalAct/legalActEdit'),
          name: '/knm_legal_act/create',
          hidden: true,
          meta: {
            title: 'Создание Правового акта КНМ',
            permissions: []
          }
        },
        {
          path: '/knm_legal_act/edit/:id(\\d+)',
          component: () => import('@/views/journals/measureKnm/knmLegalAct/legalActEdit'),
          name: '/knm_legal_act/edit',
          hidden: true,
          meta: {
            title: 'Редактирование Правового акта КНМ',
            permissions: []
          }
        }
      ]
    },
    {
      path: '/legal_acts/list',
      component: () => import('@/views/journals/legalActs/list'),
      name: 'legalActsPage',
      meta: {
        title: 'Правовые акты',
        permissions: []
      }
    },
    {
      path: '/knm_act/list',
      component: () => import('@/views/journals/measureKnm/knmAct/actList'),
      name: 'knmAct',
      meta: {
        title: 'Акт КНМ',

        permissions: []
      }
    },
    {
      path: 'breadcrumbHelper',
      name: 'breadcrumbHelperKnmAct',
      hidden: true,
      component: () => import('@/views/Home'),
      redirect: '/knm_act/list',
      meta: {
        title: 'Акт КНМ'
      },
      children: [
        {
          path: '/knm_act/create/:legal_act_id(\\d+)',
          component: () => import('@/views/journals/measureKnm/knmAct/actEdit'),
          name: '/knm_act/create',
          hidden: true,
          meta: {
            title: 'Создание Акта КНМ',
            permissions: []
          }
        },
        {
          path: '/knm_act/edit/:id(\\d+)',
          component: () => import('@/views/journals/measureKnm/knmAct/actEdit'),
          name: '/knm_act/edit',
          hidden: true,
          meta: {
            title: 'Редактирование Акта КНМ',
            permissions: []
          }
        }
      ]
    },

    {
      path: '/violations/list',
      component: () => import('@/views/journals/violations/violationList'),
      name: 'violationsPage',
      meta: {
        title: 'Нарушения',

        permissions: []
      }
    },
    {
      path: 'breadcrumbHelper',
      name: 'breadcrumbHelperViolations',
      hidden: true,
      component: () => import('@/views/Home'),
      redirect: '/violations/list',
      meta: {
        title: 'Нарушения'
      },
      children: [
        {
          path: '/violations/create',
          component: () => import('@/views/journals/violations/violationEdit'),
          name: 'violationsCreate',
          hidden: true,
          meta: {
            title: 'Создание Нарушений',
            permissions: []
          }
        },
        {
          path: '/violations/edit/:id(\\d+)',
          component: () => import('@/views/journals/violations/violationEdit'),
          name: 'violationsEdit',
          hidden: true,
          meta: {
            title: 'Редактирование Нарушений',
            permissions: []
          }
        }
      ]
    },

    {
      path: '/conducting_planned_knm/list',
      component: () => import('@/views/journals/plans/planList'),
      name: 'conductingPlannedKnm',
      meta: {
        title: 'План проведения плановых КНМ',
        permissions: []
      }
    },
    {
      path: 'breadcrumbHelper',
      name: 'breadcrumbHelperPlans',
      hidden: true,
      component: () => import('@/views/Home'),
      redirect: '/conducting_planned_knm/list',
      meta: {
        title: 'План проведения плановых КНМ'
      },
      children: [
        {
          path: '/conducting_planned_knm/create',
          component: () => import('@/views/journals/plans/planEdit'),
          name: 'planCreate',
          hidden: true,
          meta: {
            title: 'Создание Планов',
            permissions: []
          }
        },
        {
          path: '/conducting_planned_knm/edit/:id(\\d+)',
          component: () => import('@/views/journals/plans/planEdit'),
          name: 'planEdit',
          hidden: true,
          meta: {
            title: 'Редактирование Планов',
            permissions: []
          }
        }
      ]
    },

    {
      path: '/salary_po/list',
      component: () => import('@/views/journals/salary/SalaryList'),
      name: 'salaryPo',
      props: { showTable: true },
      meta: {
        title: 'Заработная плата ПО',

        permissions: []
      }
    },
    {
      path: 'breadcrumbHelper',
      name: 'breadcrumbHelperSalary',
      hidden: true,
      component: () => import('@/views/Home'),
      redirect: '/salary_po/list',
      meta: {
        title: 'Заработная плата ПО'
      },
      children: [
        {
          path: '/salary_po/view/:id(\\d+)',
          component: () => import('@/views/journals/salary/SalaryView'),
          name: '/salary_po/view',
          hidden: true,
          meta: {
            title: 'Редактирование зп ПО',
            permissions: []
          }
        }
      ]
    }
  ]
}
