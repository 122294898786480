import { RouterView } from 'vue-router'
import { knmType as knmTypeDict } from '@/utils/routerDictionaries'

export const knmType = {
  path: 'knm_type',
  component: RouterView,
  name: 'knmTypeWrap',
  redirect: '/administration/dictionaries/knm_type/list',
  meta: { title: 'Тип КНМ' },
  children: [
    {
      path: 'list',
      component: () => import('@/views/administration/dictionaries/knmType/knmTypeIndex'),
      name: 'knmTypeIndex',
      meta: {
        title: 'Тип КНМ',
        icon: 'fas fa-circle'
      }
    },
    {
      path: 'create',
      component: () => import('@/views/administration/dictionaries/dictionaryEdit'),
      name: 'knmTypeCreate',
      hidden: true,
      meta: {
        title: 'Создание',
        className: knmTypeDict.className,
        redirectOnCreated: 'knmTypeEdit'
      }
    },
    {
      path: 'edit/:id(\\d+)',
      component: () => import('@/views/administration/dictionaries/dictionaryEdit'),
      name: 'knmTypeEdit',
      hidden: true,
      meta: {
        title: 'Редактирование',
        className: knmTypeDict.className
      }
    }
  ]
}
