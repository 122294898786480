const defaultEnv = {
  TORIS_CODE: 'urn-eis-ext-eais',
  TORIS_DOMAIN: 'https://test.toris.gov.spb.ru',
  TORIS_DNS: 'test.toris.gov.spb.ru',
  TORIS_PROTOCOL: 'https:'
}

const configMap = {
  'http://eais.test.toris.vpn': {
    TORIS_DOMAIN: 'http://test.toris.vpn',
    TORIS_DNS: 'test.toris.vpn',
    TORIS_PROTOCOL: 'http:'
  },
  'http://eais.toris.vpn': {
    TORIS_DOMAIN: 'http://toris.vpn',
    TORIS_DNS: 'toris.vpn',
    TORIS_PROTOCOL: 'http:'
  },
  'https://system.eais-ktz.adc.spb.ru': {
    TORIS_DOMAIN: 'https://toris.gov.spb.ru',
    TORIS_DNS: 'toris.gov.spb.ru',
    TORIS_PROTOCOL: 'https:'
  },
  'https://system.eais-ktz-pred.adc.spb.ru': {
    TORIS_DOMAIN: 'https://test.toris.gov.spb.ru',
    TORIS_DNS: 'test.toris.gov.spb.ru',
    TORIS_PROTOCOL: 'https:'
  }
}

const resolveEnv = () => {
  return configMap[window.location.origin] || {}
}

export default {
  namespaced: true,

  state: Object.assign(defaultEnv, resolveEnv())
}
