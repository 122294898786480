// IOGV
export const IOGV_FULL_ACCESS = 'iogv_full_access' // Полный доступ: чтение, создание, редактирование, удаление
// export const IOGV_READ = 'iogv_read' // Чтение всех ИОГВ
// export const IOGV_READ_ORG = 'iogv_read_org' // Чтение только ИОГВ к которому принадлежит пользователь
//    export const IOGV_CREATE = 'iogv_create'
//    export const IOGV_EDIT = 'iogv_edit'
export const IOGV_DELETE = 'iogv_delete'
export const IOGV_RESTORE = 'iogv_restore'

export const permissions = {
  create: [IOGV_FULL_ACCESS],
  edit: [IOGV_FULL_ACCESS],
  delete: [IOGV_FULL_ACCESS, IOGV_DELETE],
  restore: [IOGV_FULL_ACCESS, IOGV_RESTORE]
}
