import { RouterView } from 'vue-router'

export const calendar = {
  path: 'calendar',
  component: RouterView,
  name: 'calendarWrap',
  redirect: '/administration/calendar/list',
  children: [
    {
      path: '/administration/calendar/list',
      component: () => import('@/views/administration/calendar/calendarList.vue'),
      name: 'calendarList',
      meta: {
        title: 'Производственный календарь',
        permissions: []
      }
    }
  ]
}
