import { RouterView } from 'vue-router'

export const reportForms = {
  path: 'report-forms',
  component: RouterView,
  name: 'reportFormsWrap',
  redirect: '/administration/report-forms/list',
  meta: { title: 'Отчетные формы' },
  children: [
    {
      path: 'list',
      component: () => import('@/views/administration/PrintForm/PrintFormPage'),
      name: 'reportForms',
      meta: {
        title: 'Отчетные формы',
        type: 'XLSX',
        permissions: []
      }
    },
    {
      path: 'edit/:id(\\d+)',
      component: () => import('@/views/administration/PrintForm/PrintFormCard'),
      name: 'xlsxEditPage',
      hidden: true,
      meta: {
        title: 'Редактирование шаблона отчётной формы',
        permissions: []
      }
    }
  ]
}
