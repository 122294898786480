export function hasPermission (userPermissionList, neededPermissionList) {
  if (userPermissionList.length === 0) {
    return false
  }
  if (neededPermissionList.length === 0) {
    return true
  }
  return userPermissionList.indexOf('anything') >= 0 ||
    !neededPermissionList ||
    userPermissionList.some(permission => neededPermissionList.indexOf(permission) >= 0)
}

export function hasRoles (userRoleList, neededRoleList) {
  if (neededRoleList.length === 0) {
    return true
  }
  return userRoleList.indexOf('admin') >= 0 ||
    !neededRoleList ||
    userRoleList.some(permission => neededRoleList.indexOf(permission) >= 0)
}
