import { RouterView } from 'vue-router'

export const position = {
  path: 'position',
  component: RouterView,
  name: 'positionWrap',
  redirect: '/administration/dictionaries/position/list',
  meta: { title: 'Должности' },
  children: [
    {
      path: 'list',
      component: () => import('@/views/administration/dictionaries/position/PositionList'),
      name: 'positionIndex',
      meta: {
        title: 'Должности'
      }
    },
    {
      path: 'create',
      component: () => import('@/views/administration/dictionaries/position/PositionForm'),
      name: 'PositionCreate',
      hidden: true,
      meta: {
        title: 'Создание',
        redirectOnCreated: 'PositionEdit'
      }
    },
    {
      path: 'detail/:id(\\d+)',
      component: () => import('@/views/administration/dictionaries/position/PositionForm'),
      name: 'PositionEdit',
      hidden: true,
      meta: {
        title: 'Редактирование'
      }
    }
  ]
}
