<template>
  <el-breadcrumb
    class="app-breadcrumb"
    separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item
        v-for="(item,index) in levelList.filter(x => x.meta.title)"
        :key="item.path">
        <span
          v-if="item.meta.redirect==='no-redirect'||index===levelList.length-1"
          class="no-redirect">{{ item.meta.title }}</span>
        <a
          v-else
          @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import { compile } from 'path-to-regexp'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'BreadcrumbComponent',
  setup () {
    const router = useRouter()
    const pathCompile = (path) => {
      const { params } = router.currentRoute.value
      const toPath = compile(path)
      return toPath(params)
    }
    const handleLink = (item) => {
      const { redirect, path } = item
      if (redirect) {
        router.push(pathCompile(redirect))
        return
      }
      router.push(pathCompile(path))
    }
    const levelList = computed(() => {
      let matched = router.currentRoute.value.matched.filter(x => !!x.name)
      const first = matched[0]
      if (first && first.name.trim().toLocaleLowerCase() !== 'Главная'.toLocaleLowerCase()) {
        matched = [{ path: '/', meta: { title: 'Главная' } }].concat(matched)
      }
      return matched
    })
    return { levelList, handleLink }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;
  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
