/* eslint camelcase: 0 */
import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus'

export function getToken () {
  return Cookies.get('aistoken')
}

export function setToken (token) {
  return Cookies.set('aistoken', token)
}

export function removeToken () {
  return Cookies.remove('aistoken')
}

async function initUserData (store, router, aistoken) {
  try {
    await store.dispatch('user/panelInit')
    setToken(aistoken)
    store.dispatch('dictionary/GetAllDictionaries')
  } catch (err) {
    removeToken()
    await store.dispatch('user/panelInit')
    if (err.response.status === 500 || err.response.status === 502) {
      console.error(err)
      await router.push({ name: '500' })
    } else if (err.response.data.code === 401 || err.response.data.code === 403) {
      try {
        await router.push({ name: 'denied' })
      } catch (e) {
        console.warn('can\'t push denied, you are on denied')
      }
    } else {
      await store.dispatch('user/FedLogout')
      ElMessage.error(err || 'Ошибка верификации, пожалуйста попробуйте ещё раз.')
    }
  }
}

export function torisInit (store, router) {
  if (process.env.VUE_APP_ESOV_OPYAT_UPAL) {
    const aistoken = 'test-aistoken'
    initUserData(store, router, aistoken)
    return
  }

  try {
    window.TORIS.setOptions({
      sys_id: store.state.config.TORIS_CODE,
      is_toris: true,
      login_redirect_url: window.location.href,
      logout_redirect_url: window.location.origin + '/logout',
      domain: store.state.config.TORIS_DNS,
      domain_proto: store.state.config.TORIS_PROTOCOL,
      show_auth: true,
      css_path: window.location.origin + '/widget.css'
    })
  } catch (e) {
    store.dispatch('user/panelError')
    router.push({ name: 'denied' })
    return
  }

  window.addEventListener('TORISWidgetInitComplete', () =>
    // eslint-disable-next-line no-undef
    TORIS.userProfile(async (userProfile) => {
      console.info('Профиль пользователя:', userProfile)
      if (userProfile && userProfile.code === 103) {
        await store.dispatch('user/FedLogout')
        if (window.location.pathname !== '/denied') {
          await router.push({ path: '/' })
        }
        return
      }
      if (userProfile && userProfile.data) {
        await initUserData(store, router, userProfile.data.AISTOKEN)
      } else {
        setToken(null)
      }
    })
  )

  window.TORIS.init(function (message) {
    const element = document.getElementById('widget_iframe')
    element.style.left = 'auto';
    (new MutationObserver(mutations => {
      mutations.forEach(() => {
        if (element.style.height === '100%' && element.style.width !== '100%') {
          element.style.width = '100%'
        }
        if (element.style.height !== '100%' && element.style.width === '100%') {
          element.style.width = '20%'
        }
      })
    })).observe(element, {
      attributes: true,
      attributeFilter: ['style']
    })
    console.info('Результат первичной инициализации:', message)
  })
}
