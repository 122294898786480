import { createStore } from 'vuex'
import app from './modules/app'
import getters from './getters'
import config from './modules/config'
import user from './modules/user'
import dictionary from './modules/dictionary'

const store = createStore({
  modules: {
    app,
    user,
    config,
    dictionary
  },
  getters
})
export default store
