// Карточка Акт КНМ
export const ACT_KNM_FULL_ACCESS = 'act_knm_full_access' // Полный доступ: чтение, создание, редактирование, удаление
// export const ACT_KNM_READ = 'act_knm_read' // Чтение всех Акт КНМ
export const ACT_KNM_CREATE = 'act_knm_create' // Создание Акт КНМ
export const ACT_KNM_EDIT_ORG = 'act_knm_edit_org' // Редактирование Акт КНМ которые принадлежат к его ИОГВ. Даже если создавал не он
export const ACT_KNM_EDIT_KTZN = 'act_knm_edit_ktzn' // Редактирование Акт КНМ только своих (*Кто создал, т.е. автор карточки)
export const ACT_KNM_DELETE_KTZN = 'act_knm_delete_ktzn' // Удаление Акт КНМ только своих (*Кто создал, т.е. автор карточки)
export const ACT_KNM_DELETE = 'act_knm_delete'
export const ACT_KNM_RESTORE = 'act_knm_restore'
export const ACT_KNM_RESTORE_KTZN = 'act_knm_restore_ktzn'

export const permissions = {
  create: [ACT_KNM_FULL_ACCESS, ACT_KNM_CREATE],
  edit: [ACT_KNM_FULL_ACCESS, ACT_KNM_EDIT_ORG, ACT_KNM_EDIT_KTZN],
  delete: [ACT_KNM_FULL_ACCESS, ACT_KNM_DELETE_KTZN, ACT_KNM_DELETE],
  restore: [ACT_KNM_FULL_ACCESS, ACT_KNM_RESTORE, ACT_KNM_RESTORE_KTZN]
}
