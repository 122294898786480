import { hasPermission } from '@/store/modules/permission'
import { getToken, removeToken } from '@/utils/auth'
import permissionService from '@/services/permissions/PermissionService'

const SettingsKey = 'user-settings'

const user = {
  namespaced: true,

  state: {
    token: getToken(),
    id: null,
    profile: {},
    settings: {},
    roles: [],
    permissions: [],
    isPanelInit: false,
    panelInitError: false
  },

  getters: {
    hasRole: state => roles => hasPermission(state.roles, roles),
    hasPermission: state => permissions => hasPermission(state.permissions, permissions)
  },

  mutations: {
    SET_PROFILE: (state, profile) => {
      state.profile = profile
      state.username = profile.USER_FIO ?? profile.USER_ESOV_LOGIN ?? 'Не задано'
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_PANEL_STATE: (state, bool) => {
      state.isPanelInit = bool
    },
    SET_PANEL_INIT_ERROR: (state, bool) => {
      state.panelInitError = bool
    }
  },

  actions: {
    GetUserInfo (context, href = '/api/auth/access/info') {
      context.commit('SET_TOKEN', getToken())
      return fetch(href, {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + getToken()
        }
      }).then(response => {
        if (response.status !== 200) {
          context.dispatch('FedLogout')
          throw new Error('Не удалось получить информацию пользователя по токену')
        }
        return response.json()
      }).then(data => {
        if (!data) {
          throw new Error('request doesn\'t return data')
        }
        if (data.roles && data.roles.length > 0) {
          context.commit('SET_ROLES', data.roles)
          context.commit('SET_PERMISSIONS', data.permissions)
          permissionService.user = data
          context.commit('SET_ID', data.id)
        } else {
          throw new Error('getInfo: roles must be a non-null array !')
        }
        return data
      })
    },

    FedLogout ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', undefined)
        commit('SET_ROLES', [])
        commit('SET_PERMISSIONS', [])
        removeToken()
        resolve()
      })
    },
    UpdateSettings ({ state }) {
      window.localStorage.setItem(SettingsKey, JSON.stringify(state.settings))
    },
    panelInit ({ commit }) {
      commit('SET_PANEL_STATE', true)
    },
    panelError ({ commit }) {
      commit('SET_PANEL_INIT_ERROR', true)
    }
  }
}

export default user
