const getters = {
  dateFormat: state => state.app.dateFormat,
  dateValueFormat: state => state.app.dateValueFormat,
  dateTimeFormat: state => state.app.dateTimeFormat,
  dateTimeValueFormat: state => state.app.dateTimeValueFormat,
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  token: state => state.user.token,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions
}
export default getters
