<template>
  <div
    v-if="canShowItem(item)"
    class="menu-wrapper">
    <template
      v-if="showItemLikeOneParent(item)">
      <app-link :to="resolvePath(item)">
        <el-menu-item
          :index="resolvePath(item)"
          :class="{'sub-menu-title-noDropdown':!isNest}">
          <ItemTemplate :item="getRealParentItem(item)" />
        </el-menu-item>
      </app-link>
    </template>
    <el-sub-menu
      v-else
      ref="submenu"
      :index="resolvePath(item)">
      <template #title>
        <div
          v-if="item.meta"
          class="menu-container">
          <i :class="item.meta.icon" />
          <span v-html="item.meta.title" />
        </div>
      </template>
      <template v-for="child in item.children.filter(x => !x.hidden && hasPermission(x))">
        <sidebar-item
          v-if="child.children&&child.children.length>0"
          :key="child.path"
          :is-nest="true"
          :item="child"
          :base-path="resolvePath(child)"
          class="nest-menu" />
        <app-link
          v-else
          :key="child.name"
          :to="resolvePath(child)">
          <el-menu-item :index="resolvePath(child)">
            <div
              v-if="child.meta"
              :class="isCollapse ? 'menu-container-collapse' : 'menu-container'">
              <i
                :class="child.meta.icon"
                :title="child.meta.title" />
              <span v-html="child.meta.title" />
            </div>
          </el-menu-item>
        </app-link>
      </template>
    </el-sub-menu>
  </div>
</template>

<script>
import { isExternal } from '@/utils'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'
import { hasPermission } from '@/store/modules/permission'
import ItemTemplate from '@/components/Sidebar/ItemTemplate.vue'

export default {
  name: 'SidebarItem',
  components: { ItemTemplate, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    isCollapse: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  methods: {
    hasOneParentShowingChild (parent) {
      const showingChildrens = parent.children.filter(item => {
        // Оставлено в виде if т.к. иначе неправильно работает
        if (!item.hidden && this.hasPermission(item)) {
          return true
        } else {
          return false
        }
      })

      // Считаем что это действительно родитель
      // только если выполняются следующие условия
      if (showingChildrens.length === 0) {
        return true
      }

      if (showingChildrens.length === 1) {
        if (showingChildrens[0].path.length === 0) {
          return true
        }

        const regexObj = new RegExp(showingChildrens[0].path + '$')
        if (parent.path === showingChildrens[0].path ||
          (parent?.redirect?.length > 0 && regexObj.test(parent?.redirect))
        ) {
          return true
        }
      }

      return false
    },
    resolvePath (routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath
      }

      if (!routePath.name && this.hasOneParentShowingChild(routePath)) {
        return this.$router.resolve({ name: routePath.children[0].name, params: this.$route.params }).href
      }

      if (this.hasChildrensToView(routePath)) {
        for (const children of routePath.children) {
          const resolved = this.$router.resolve({ name: children.name, params: this.$route.params })
          if (resolved.href) {
            return resolved.href
          }
        }
      }

      return this.$router.resolve({ name: routePath.name, params: this.$route.params }).href
    },
    isExternalLink (routePath) {
      return isExternal(routePath)
    },
    hasPermission (route) {
      return hasPermission(this.$store.state.user.permissions, route.meta?.permissions ?? [])
    },
    hasChildrensToView (item) {
      return item.children && item.children.filter(x => !x.hidden && this.hasPermission(x)).length > 0
    },
    canShowItem (item) {
      return !item.hidden && this.hasPermission(item)
    },
    showItemLikeOneParent (item) {
      return this.hasOneParentShowingChild(item) && !item.alwaysShow
    },
    getRealParentItem (item) {
      if (item.children.length && this.hasOneParentShowingChild(item) && item.children[0].meta) {
        return item.children[0]
      } else if (item.meta) {
        return item
      }
    }
  }
}
</script>
<style scoped>
.el-menu-item {
  white-space: pre-line;
  line-height: 25px;
  height: unset;
  min-height: 30px;
}

.menu-container-collapse {
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  margin: unset;
}

.is-active > .el-menu {
  background-color: #1f2d3d !important;
}

.is-active > .el-sub-menu__title {
  color: #f4f4f5 !important;
}

.is-active.el-menu-item {
  color: #ffffff !important;
}

</style>
