<template>
  <section class="app-main">
    <router-view v-slot="{ Component, route }">
      <transition
        name="fade-transform"
        mode="out-in">
        <component
          :is="Component"
          :key="route.path" />
      </transition>
    </router-view>
  </section>
</template>

<script>
export default {
  name: 'AppMain'
}
</script>

<style scoped>
.app-main {
  min-height: calc(100vh - 173px);
  width: 100%;
  position: relative;
  overflow: hidden;
}
</style>
