import Layout from '@/views/layout/Layout'

export default {
  path: '/personal',
  name: 'personal',
  component: Layout,
  redirect: '/personal/notifications',
  meta: {
    title: 'Личный кабинет',
    permissions: []
  },
  children: [
    {
      path: '/personal/notifications',
      component: () => import('@/views/personal/notifications/list'),
      name: 'notificationsList',
      meta: {
        title: 'Уведомления',
        permissions: []
      }
    }
  ]
}
