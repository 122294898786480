import store from '@/store'

export default function checkPermission (value) {
  if (value.length > 0) {
    const userRoles = store.getters?.roles
    return userRoles?.some(role => value.includes(role)) ?? false
  }

  return false
}

export function resolveHome () {
  // eslint-disable-next-line no-constant-condition
  if (true) {
    return 'logKnm'
  }

  return 'denied'
}
