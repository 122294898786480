<template>
  <!-- eslint-disable vue/require-component-is-->
  <component
    :is="type"
    v-bind="linkProps(to)">
    <slot />
  </component>
</template>

<script>
import { isExternal } from '@/utils'

export default {
  name: 'LinkView',
  props: {
    to: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      type: 'a'
    }
  },
  methods: {
    isExternalLink (routePath) {
      return isExternal(routePath)
    },
    linkProps (url) {
      if (this.isExternalLink(url)) {
        this.type = 'a'
        return {
          href: url,
          target: '_blank',
          rel: 'noopener'
        }
      }
      this.type = 'router-link'
      return {
        to: url
      }
    }
  }
}
</script>
