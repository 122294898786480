import { hasPermission } from '@/store/modules/permission'
import store from '@/store'

// Карточка Заработная плата
export const SALARY_FULL_ACCESS = 'salary_full_access' // Полный доступ: чтение, создание, редактирование, удаление
export const SALARY_READ = 'salary_read' // Чтение всех Заработная плата
export const SALARY_CREATE = 'salary_create' // Создание Заработная плата
export const SALARY_EDIT_ORG = 'salary_edit_org' // Редактирование Заработная плата которые принадлежат к его ИОГВ. Даже если создавал не он
export const SALARY_DELETE = 'salary_delete'
export const SALARY_RESTORE = 'salary_restore'
export const SALARY_IMPORT = 'salary_import'

export const permissions = {
  create: [SALARY_FULL_ACCESS, SALARY_CREATE],
  edit: [SALARY_FULL_ACCESS, SALARY_EDIT_ORG],
  delete: [SALARY_FULL_ACCESS, SALARY_DELETE],
  restore: [SALARY_FULL_ACCESS, SALARY_RESTORE],
  import: [SALARY_FULL_ACCESS, SALARY_IMPORT]
}

class SalaryService {
  canImport () {
    return hasPermission(store.state.user.permissions, permissions.import)
  }
}

export const salaryService = new SalaryService()
