import { hasPermission } from '@/store/modules/permission'
import { permissions as iogvPermissions } from '@/services/permissions/IogvPermissions'
import { permissions as suborgPermissions } from '@/services/permissions/suborgPermissions'
import { permissions as knmPermissions } from '@/services/permissions/knmPermissions'
import { permissions as violationPermissions } from '@/services/permissions/violationPermissions'
import { permissions as actKnmPermissions } from '@/services/permissions/actPermissions'
import { permissions as planKnmPermissions } from '@/services/permissions/planKnmPermissions'
import { permissions as salaryPermissions } from '@/services/permissions/salary'
import { permissions as legalActPermissions } from '@/services/permissions/legalActPermissions'

export const KTZN_OGRN = '1079847017910'

export const ANYTHING = 'anything'

// Шаблоны документов
// export const DOC_TEMPLATE_FULL_ACCESS = 'doc_template_full_access' // Полный доступ: чтение, создание, редактирование, удаление
// export const DOC_TEMPLATE_READ = 'doc_template_read' // Чтение

export const REPORT_VIEW = 'report_view' // Просмотр печатных форм для docx и xlsx
export const ADMIN_VIEW = 'admin_view' // Просмотр раздела администрирования

class PermissionService {
  user = {}

  permissionList = {
    iogv: iogvPermissions,
    suborg: suborgPermissions,
    knm: knmPermissions,
    violation: violationPermissions,
    act_knm: actKnmPermissions,
    plan_knm: planKnmPermissions,
    salary_knm: salaryPermissions,
    dictionary: {
      create: [],
      edit: [],
      delete: [],
      restore: []
    },
    legal_act_knm: legalActPermissions
  }

  canCreate (entity) {
    return hasPermission(this.user.permissions, this.getPermissionByEntityAndType(entity, 'create'))
  }

  canEdit (entity, iogv) {
    // получаем список необходимых прав для действия
    const neededPermissions = this.getPermissionByEntityAndType(entity, 'edit')
    // проверяем есть ли у пользователя такое право/права
    if (hasPermission(this.user.permissions, neededPermissions)) {
      // получаем пересечение прав
      const intersection = this.user.permissions.filter(x => neededPermissions.includes(x))
      // перебираем все полученные права пользователя от пересечения
      for (const intersectionElement of intersection) {
        // Если это право требует "только ПО, которые принадлежат к его ИОГВ. Даже если создавал не он"
        if (intersectionElement.includes('_org')) {
          return iogv?.id === this.user.organization
        }

        // если это право для фильтрации только ИОГВ - КТЗН
        if (intersectionElement.includes('_ktzn')) {
          return iogv?.ogrn === KTZN_OGRN // огрн КТЗН
        }
      }
      return true
    }

    return false
  }

  canDelete (entity, form, ogrnField = '') {
    // получаем список необходимых прав для действия
    const neededPermissions = this.getPermissionByEntityAndType(entity, 'delete')
    // проверяем есть ли у пользователя такое право/права
    if (hasPermission(this.user.permissions, neededPermissions)) {
      // получаем пересечение прав
      const intersection = this.user.permissions.filter(x => neededPermissions.includes(x))

      // перебираем все полученные права пользователя от пересечения
      for (const intersectionElement of intersection) {
        // Если это право требует "только ПО, которые принадлежат к его ИОГВ. Даже если создавал не он"
        if (intersectionElement.includes('_org')) {
          return form?.iogv_id === this.user.organization
        }

        // если это право для фильтрации только ИОГВ - КТЗН
        if (intersectionElement.includes('_ktzn')) {
          return ogrnField === KTZN_OGRN // огрн КТЗН
        }
      }
      return true
    }

    return false
  }

  canRestore (entity, form, ogrnField = '') {
    // получаем список необходимых прав для действия
    const neededPermissions = this.getPermissionByEntityAndType(entity, 'restore')
    // проверяем есть ли у пользователя такое право/права
    if (hasPermission(this.user.permissions, neededPermissions)) {
      // получаем пересечение прав
      const intersection = this.user.permissions.filter(x => neededPermissions.includes(x))

      // перебираем все полученные права пользователя от пересечения
      for (const intersectionElement of intersection) {
        // Если это право требует "только ПО, которые принадлежат к его ИОГВ. Даже если создавал не он"
        if (intersectionElement.includes('_org')) {
          return form?.iogv_id === this.user.organization
        }

        // если это право для фильтрации только ИОГВ - КТЗН
        if (intersectionElement.includes('_ktzn')) {
          return ogrnField === KTZN_OGRN // огрн КТЗН
        }
      }
      return true
    }

    return false
  }

  getPermissionByEntityAndType (entity, type) {
    if (entity in this.permissionList) {
      return this.permissionList[entity][type]
    }
    throw new Error('unknown entity: ' + entity)
  }
}

const permissionService = new PermissionService()
export default permissionService
