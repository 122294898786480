import { RouterView } from 'vue-router'

export const printedForms = {
  path: 'printed-forms',
  component: RouterView,
  name: 'printedFormsWrap',
  redirect: '/administration/printed-forms/list',
  meta: { title: 'Печатные формы' },
  children: [
    {
      path: 'list',
      component: () => import('@/views/administration/PrintForm/PrintFormPage'),
      name: 'printedForms',
      meta: {
        title: 'Печатные формы',
        type: 'DOCX',
        permissions: []
      }
    },
    {
      path: 'edit/:id(\\d+)',
      component: () => import('@/views/administration/PrintForm/PrintFormCard'),
      name: 'docxEditPage',
      hidden: true,
      meta: {
        title: 'Редактирование шаблона печатной формы',
        permissions: []
      }
    }
  ]
}
