import { RouterView } from 'vue-router'
import { industryAffiliation as iaDict } from '@/utils/routerDictionaries'

export const industryAffiliation = {
  path: 'industry_affiliation',
  component: RouterView,
  name: 'industryAffiliationWrap',
  redirect: '/administration/dictionaries/industry_affiliation/list',
  meta: { title: 'Отраслевая принадлежность' },
  children: [
    {
      path: 'list',
      component: () => import('@/views/administration/dictionaries/industryAffiliation/industryAffiliationIndex'),
      name: 'industryAffiliationIndex',
      meta: {
        title: 'Отраслевая принадлежность',
        icon: 'fas fa-circle'
      }
    },
    {
      path: 'create',
      component: () => import('@/views/administration/dictionaries/dictionaryEdit'),
      name: 'industryAffiliationCreate',
      hidden: true,
      meta: {
        title: 'Создание',
        className: iaDict.className,
        redirectOnCreated: 'industryAffiliationEdit'
      }
    },
    {
      path: 'edit/:id(\\d+)',
      component: () => import('@/views/administration/dictionaries/dictionaryEdit'),
      name: 'industryAffiliationEdit',
      hidden: true,
      meta: {
        title: 'Редактирование',
        className: iaDict.className
      }
    }
  ]
}
