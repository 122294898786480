// Карточка Нарушения
export const VIOLATION_FULL_ACCESS = 'violation_full_access' // Полный доступ: чтение, создание, редактирование, удаление
export const VIOLATION_READ = 'violation_read' // Чтение всех Нарушения
export const VIOLATION_CREATE = 'violation_create' // Создание Нарушения
export const VIOLATION_EDIT_ORG = 'violation_edit_org' // Редактирование Нарушения которые принадлежат к его ИОГВ. Даже если создавал не он
export const VIOLATION_EDIT_KTZN = 'violation_edit_ktzn' // Редактирование Нарушения только своих (*Кто создал, т.е. автор карточки)
export const VIOLATION_DELETE_KTZN = 'violation_delete_ktzn' // Удаление Нарушения только своих (*Кто создал, т.е. автор карточки)
export const VIOLATION_DELETE = 'violation_delete'
export const VIOLATION_RESTORE = 'violation_restore'
export const VIOLATION_RESTORE_KTZN = 'violation_restore_ktzn'

export const permissions = {
  create: [VIOLATION_FULL_ACCESS, VIOLATION_CREATE],
  edit: [VIOLATION_FULL_ACCESS, VIOLATION_EDIT_ORG, VIOLATION_EDIT_KTZN],
  delete: [VIOLATION_FULL_ACCESS, VIOLATION_DELETE_KTZN, VIOLATION_DELETE],
  restore: [VIOLATION_FULL_ACCESS, VIOLATION_RESTORE, VIOLATION_RESTORE_KTZN]
}
