export const dictionaryRoutes = [
  {
    path: '/dictionaries/:className(\\w+)/create',
    component: () => import('@/views/administration/dictionaries/dictionaryEdit'),
    name: 'dictionaryCreate',
    hidden: true
  },
  {
    path: '/dictionaries/:className(\\w+)/edit/:id(\\d+)',
    component: () => import('@/views/administration/dictionaries/dictionaryEdit'),
    name: 'dictionaryEdit',
    hidden: true
  }
]

export const knmType = {
  className: 'knm_type',
  historyModel: 'App\\Models\\DictionaryKnmType',
  label: 'Тип КНМ'
}

export const industryAffiliation = {
  className: 'industry_affiliation',
  historyModel: 'App\\Models\\IndustryAffiliation',
  label: 'Отраслевая принадлежность'
}

export const dictionaryList = [
  knmType,
  industryAffiliation
]
