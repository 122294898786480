// ПО - подведомственная организация
export const SUBORG_FULL_ACCESS = 'suborg_full_access' // Полный доступ: чтение, создание, редактирование, удаление
// export const SUBORG_READ = 'suborg_read' // Чтение всех ПО
export const SUBORG_CREATE = 'suborg_create' // Создание ПО
export const SUBORG_EDIT_ORG = 'suborg_edit_org' // Редактирование ПО которые принадлежат к его ИОГВ. Даже если создавал не он
export const SUBORG_EDIT_KTZN = 'suborg_edit_ktzn' // Редактирование ПО только своих (*Кто создал, т.е. автор карточки)
export const SUBORG_DELETE_KTZN = 'suborg_delete_ktzn' // Удаление ПО только своих (*Кто создал, т.е. автор карточки)
export const SUBORG_DELETE = 'suborg_delete'
export const SUBORG_RESTORE = 'suborg_restore'
export const SUBORG_RESTORE_KTZN = 'suborg_restore_ktzn'

export const permissions = {
  create: [SUBORG_FULL_ACCESS, SUBORG_CREATE],
  edit: [SUBORG_FULL_ACCESS, SUBORG_EDIT_ORG, SUBORG_EDIT_KTZN],
  delete: [SUBORG_FULL_ACCESS, SUBORG_DELETE_KTZN, SUBORG_DELETE],
  restore: [SUBORG_FULL_ACCESS, SUBORG_RESTORE, SUBORG_RESTORE_KTZN]
}
