import { RouterView } from 'vue-router'

export const imports = {
  path: 'imports',
  component: RouterView,
  name: 'downloadsWrap',
  redirect: '/administration/imports/list',
  children: [
    {
      path: '/administration/imports/list',
      component: () => import('@/views/administration/imports/importsList.vue'),
      name: 'downloadsList',
      meta: {
        title: 'Журнал загрузок ',
        permissions: []
      }
    }
  ]
}
